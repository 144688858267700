.App {
  text-align: center;
  display: grid;
  /*grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: minmax(200px, auto);*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Semester {
  display: inline-block;
  font-size: 16px;
  width: 150px;
  vertical-align: middle;
  padding-top: 10px;
}

.mainPanel {
  font-size: 14px;
  display: inline-block;
  width: auto;
  justify-content: left;
  justify-self: center;
  margin: auto;
  margin-top: 10px;
  align-self: left;
}


th {
  font-size: 10px;
  vertical-align: bottom;
  padding-top: 10px; 
  padding-bottom: 0px; 
  font-weight: 200px;
  color: grey
}

.summary {
  font-size: 20px;
  vertical-align: bottom; 
  padding-bottom: 0px; 
  font-weight: 200px;
  color: rgb(255, 255, 255)
}

.sidePanel {
  padding-top: 20px;
  align-items: center;
  display: inline-block;
  vertical-align: top;
}

.infoPanel {
  padding-top: 20px;
  display: inline-block;
  font-size: 16px;
  vertical-align: top;
  align-self: left;
  justify-content: left;
}

.infoPanel h3 {
  color: rgb(138, 138, 138);
  font-size: 22px;
}

.hypoCourse .courseName{
  width: 40%
}

.hypoCourse .credits{
  width: 20%
}

.years{
  justify-self: right;
  justify-content: right;
  align-items: right;
  vertical-align: bottom;
  padding: 30px;
}

.row tr {
  justify-content: right;
  width: 10px
}

.row input {
  justify-content: right;
  width: 150px;
}

.body {
  width: 100%;
  background-color: #2e323b;
}